<script>
import {list} from "@/domain/fenaju/services"
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage, UTooltip, UProgress, UPopover} from 'uloc-vue'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import {ErpInput, /*ErpSelect,*/ ErpSField} from 'uloc-vue-plugin-erp'
//import DefaultMixin from '../mixin'
import StatusSelect from "components/fenaju/components/clientes/include/StatusSelect.vue"
import {datePtToEn} from '@/utils/date'
import ECol from "components/layout/components/Col.vue"
import EBtn from "@/plugins/uloc-erp/components/button/EBtn"
import ERow from "components/layout/components/Row.vue"
import FiltrosAdicionais from "components/fenaju/components/clientes/include/ListFilters.vue"
import listOptions from "components/usuario/v2/window/listOptions"
import filters from "@/domain/fenaju/helpers/filtersLista"
import {addDays, differenceInHours, endOfWeek, format, parseISO, startOfWeek, subDays} from "date-fns"
import {listName, listStorage} from "@/components/fenaju/components/clientes/config/list"
import CheckSvg from "@/assets/images/CheckSvg.vue"
import AlertSvg from "@/assets/images/AlertSvg.vue"
import StatusCliente from "components/clientes/components/cliente/include/StatusCliente.vue"
import SatisfacaoRating from "components/clientes/components/cliente/include/SatisfacaoRating.vue"
import criarClienteWindow from "components/fenaju/components/window/registroWindow"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue"
import {status} from "@/domain/fenaju/helpers"

export default {
  name: 'PedidosList',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [
    //DefaultMixin
  ],
  components: {
    MenuOptionsItem,
    //AlertSvg,
    //CheckSvg,
    ERow,
    ECol,
    // DateInput,
    StatusSelect,
    // SearchFilter,
    UTable,
    UTh,
    UTr,
    UTd,
    //UCheckbox,
    ErpInput,
    EBtn,
    // ErpSelect,
    ErpSField,
    FiltrosAdicionais,
    UPopover
    //UTooltip,
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  data () {
    return {
      listName: listName,
      listStorage: this.listStorage,
      filtersData: {
        uf: {
          loading: true,
          serverData: []
        }
      },
      showFilters: false,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      stats: {
        cadastradosHoje: 0,
        cadastradosSemana: 0,
        paraAnalisar: 0
      },
      statsFilterActive: null
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    menuDashOptions () {
      return MenuOptions
    },
    status () {
      /*return Object.keys(status).map(k => {
        const v = status[k]
        return {
          ...v,
          value: Number(k)
        }
      })*/
      return status
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    },
    isOpen (task) {
      return task.status.type === 'opened'
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    abrir(t) {
      this.$router.push({
        name: 'fenaju.clientes.cliente',
        params: {
          id: t.id
        }
      })
    },
    trClass (task) {
      const css = []
      if (this.colorize) {
        if (task.deadline && this.atraso(task.deadline) > 0) {
          css.push('sla-out')
        }
      }
      return css
    },
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }
      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.search && extraFilters.push(`&search=${String(this.table.filters.search).replace('#', '_hash-')}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.solicitante && extraFilters.push(`&solicitante=${this.table.filters.solicitante.id}`)
      this.table.filters.status.length && extraFilters.push(`&status=${this.table.filters.status.join(',')}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dominio'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : false}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, this.isSuporte)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.search
      })
    },
    tabFilter (t) {
      if (this.table.filters.typeFilter === t) {
        this.table.filters = JSON.parse(JSON.stringify(filters))
        this.load()
        return
      }
      this.table.filters = JSON.parse(JSON.stringify(filters))
      this.table.filters.typeFilter = t
      this.load()
    },
    criarClienteWindow,
    criarCliente () {
      this.criarClienteWindow(null, null)
    },
    editarCliente (c) {
      this.criarClienteWindow(c.id)
    }
  },
  meta: {
    title: 'Pedidos',
    name: 'Pedidos'
  }
}
</script>

<template>
  <div v-if="$route.name === 'fenaju.clientes.lista'" class="erp-list-task console-pad">
    <div class="flex justify-between">
      <div class="console-task-opts">
        <div class="cleft"></div>
        <div class="cright">
          <u-btn @click="criarCliente" no-caps color="green" class="tbtn">
            <i class="fa-duotone fa-user-plus m-r-sm" /> Novo Registro
          </u-btn>
        </div>
      </div>
      <!--      <div class="flex justify-end m-t m-b-lg">
              <u-btn @click="novo" class="sl-btn" color="green" no-caps v-shortkey="['shift', 'n']"
                     @shortkey.native="novo">Cadastrar <span class="shortcut font-10 m-l">Shift + N</span></u-btn>
            </div>-->
    </div>
    <u-table
        ref="table"
        color="primary"
        :data="table.serverData"
        :columns="table.columns"
        :visible-columns="visibledColumns"
        :filter="table.busca"
        row-key="id"
        :pagination.sync="table.serverPagination"
        @request="request"
        selection="multiple"
        :selected.sync="table.selected"
        :loading="table.loading"
        class="erp-table table-v2 table-shadow"
        :rows-per-page-options="[20, 50, 100]"
    >
      <template slot="top" slot-scope="props">
        <div class="sl-v2-table-filters full-width">
          <e-row class="e-input-modern size1">
            <e-col style="min-width: 150px" class="m-l m-r">
              <erp-s-field
                  view="ll"
                  label="Busca"
              >
                <erp-input placeholder="Busca Inteligente" v-on:keyup.enter="pesquisar" shortkey="F2" v-model="table.filters.search" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 100px" class="m-l m-r">
              <erp-s-field
                  view="ll"
                  label="ID"
              >
                <erp-input v-on:keyup.enter="pesquisar" shortkey="F3" v-model="table.filters.id" />
              </erp-s-field>
            </e-col>
            <e-col style="min-width: 150px" class="m-l m-r">
              <status-select size="2" label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                             v-model="table.filters.status" multiple/>
            </e-col>
            <e-col style="max-width: 80px">
              <e-btn @click="pesquisar" label="Filtrar" />
              <!--                  <u-btn @click="pesquisar" color="grey" size="sm" class="app-btn erp-btn text-white" no-caps>Filtrar</u-btn>-->
            </e-col>
            <e-col class="flex items-end content-end justify-end">
              <u-btn @click="showFilters = !showFilters" color="blue-grey-1" size="sm" class="app-btn text-blue-grey-5" no-caps>
                <!--    Mais filtros-->
                <u-icon name="filter" class="m-l__ m-r-sm fin-icon-filter" type="fa"/>
                <span v-if="!showFilters">Mais Filtros</span>
                <span v-else>Menos Filtros</span>
              </u-btn>
            </e-col>
          </e-row>
          <e-row>
            <filtros-adicionais :filters="table.filters" v-show="showFilters" />
          </e-row>
        </div>
      </template>
      <u-tr slot="header" slot-scope="props">
        <!--        <u-th auto-width v-if="mode !== 'cliente'">
                  <u-checkbox
                      v-model="props.selected"
                      :indeterminate="props.partialSelected"
                      size="xs"
                  />
                </u-th>-->
        <u-th v-for="col in props.cols" :key="col.name" :props="props" :class="'col-' + col.name">
          <div v-if="col.name === 'options'" class="text-center">
            <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
          </div>
          <template v-else>
            <span v-if="col.name === 'nome'">{{col.label}} <small class="text-blue-grey">({{table.serverPagination.rowsNumber}})</small></span>
            <span v-else>{{col.label}}</span>
          </template>
        </u-th>
      </u-tr>

      <u-tr slot="body" slot-scope="props"
            :props="props" :class="[{'u-table-item-destac': props.row.isToday}, trClass(props.row)]">
        <!--        <u-td class="pos-rlt" auto-width v-if="mode !== 'cliente'">
                  <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                </u-td>-->
        <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
          {{ String(props.row.id).padStart(4, '0') }}
        </u-td>
        <u-td key="dominio" :props="props">
          <router-link :to="{name: 'fenaju.clientes.cliente', params: {id: props.row.id}}">{{ props.row.dominio }}</router-link>
        </u-td>
        <u-td class="" key="cliente" :props="props">
          <div class="flex items-center">
            <div class="app-person-avatar m-r-sm flex justify-center full-width">
              <img v-if="props.row.solicitante && props.row.solicitante.photo" :src="props.row.solicitante.photo">
              <div v-else-if="props.row.solicitante" class="img-fake" style="background-color: #399B9F">{{ firstLettersName(props.row.solicitante.name) }}</div>
              <div v-else class="img-fake" style="background-color: #399B9F">--</div>
            </div>
            <div class="m-l-xs flex items-center column">
              <div>
                <div v-if="props.row.solicitante">
                  {{props.row.solicitante.name}}
                  <u-tooltip :offset="[10,10]">
                    {{props.row.solicitante.name}}
                  </u-tooltip>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </div>
        </u-td>
        <u-td key="documentacao" :props="props">
          <div v-if="[3,90,100].includes(props.row.status)">Ok</div>
          <div v-else style="color: #A30D0D">Pendente</div>
        </u-td>
        <u-td key="pagamento" :props="props">
          <div v-if="[90,100].includes(props.row.status)">Ok</div>
          <div v-else style="color: #A30D0D">Pendente</div>
        </u-td>
        <u-td key="createdAt" :props="props">
          {{props.row.createdAt|formatDate}}
        </u-td>
        <u-td key="dataRegistro" :props="props">
          <div v-if="props.row.dataRegistro">{{props.row.dataRegistro|formatDate('dd/MM/yyyy')}}</div>
          <div v-else>-</div>
        </u-td>
        <u-td key="dataExpiracao" :props="props">
          <div v-if="props.row.dataExpiracao">{{props.row.dataExpiracao|formatDate('dd/MM/yyyy')}}</div>
          <div v-else>-</div>
        </u-td>
        <u-td class="" key="status" :props="props">
          <div :class="'dominio-status-code-' + props.row.status" :style="'color: ' + status[props.row.status].color">{{status[props.row.status].label}}</div>
        </u-td>
        <u-td class="text-right" key="options" :props="props">
          <div class="cursor-pointer m-r">
            <div class="text-right no-select">Ações <i class="fa fa-chevron-down text-grey-5 font-10" /></div>
            <u-popover class="window-context-menu menu-profile min" anchor="bottom right" self="top right">
              <ul>
                <menu-options-item close label="Abrir" @click="$emit('abrir')" />
                <menu-options-item close label="Editar" @click="editarCliente(props.row)" />
              </ul>
            </u-popover>
          </div>
        </u-td>
      </u-tr>
    </u-table>
  </div>
  <router-view v-else />
</template>
